import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo1 from "../../images/logo1.png";
import Logout from "../../images/Logout.png";
import "../../styles/student/mobileHeader.css";
import AuthService from "../auth/AuthService";
const MobileHeader = ({ setActiveTab }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const menuRef = useRef(null); // Reference for the menu to detect clicks outside
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await AuthService.logout();
      navigate("/home");
      // Handle logout logic (like redirecting to login page)
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState); // Toggle the menu visibility
  };

  const closeMenu = () => {
    setIsMenuOpen(false); // Close the menu
  };

  // Close the menu if the user clicks outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !event.target.closest(".burger")
      ) {
        setIsMenuOpen(false); // Close menu when clicking outside
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleNavItemClick = (tab) => {
    setActiveTab(tab); // Set the active tab
    closeMenu(); // Close the menu when a navigation item is clicked
  };

  return (
    <div className="mobile-header1">
      <div className="header-left" style={{ marginLeft: "0.5em" }}>
        <img src={logo1} className="menu-icon6" alt="Graduation Cap Icon" />{" "}
        <p style={{ marginTop: "21.5px" }}>DZSCHOOL.ONLINE</p>
      </div>
      <div className="header-right1">
        {/* Hamburger Menu Button (for mobile) */}
        <div
          onClick={toggleMenu}
          className="burger"
          aria-label="Toggle navigation"
        >
          <span className="burger-icon"></span>
          <span className="burger-icon"></span>
          <span className="burger-icon"></span>
        </div>
      </div>
      {/* Mobile Navigation Menu */}
      {isMenuOpen && (
        <div className="navbar-collapse" ref={menuRef}>
          <ul className="navbar-nav">
            {/* Main Navigation Links */}
            <li onClick={() => handleNavItemClick("home")}>
              {/* <img src={home} className="menu-icon" alt="Home Icon" /> */}
              {t("Home")}
            </li>
            <li onClick={() => handleNavItemClick("calendar")}>
              {/* <img src={calendar} className="menu-icon" alt="Calendar Icon" /> */}
              {t("Calendar")}
            </li>
            <li onClick={() => handleNavItemClick("courses")}>
              {/* <img
                src={GraduationIcon}
                className="menu-icon"
                alt="Courses Icon"
              /> */}
              {t("Courses")}
            </li>
            <li onClick={() => handleNavItemClick("student")}>
              {/* <img src={student} className="menu-icon" alt="Student Icon" /> */}
              {t("Student Info")}
            </li>
            <li onClick={() => handleNavItemClick("inbox")}>
              {/* <img src={mail} className="menu-icon" alt="Inbox Icon" /> */}
              {t("Inbox")}
            </li>
            <li onClick={() => handleNavItemClick("bills")}>
              {/* <img src={billIcon} className="menu-icon" alt="Bill Icon" /> */}
              {t("Bills")}
            </li>
            <li onClick={() => handleNavItemClick("contact")}>
              {/* <img src={contactIcon} className="menu-icon" alt="Contact Icon" /> */}
              {t("Contact")}
            </li>

            {/* Language Selection Dropdown */}
            <li>
              <select
                onChange={(e) => i18n.changeLanguage(e.target.value)}
                defaultValue="en"
                aria-label={t("language")}
              >
                {["en", "ar", "fr"].map((lang) => (
                  <option key={lang} value={lang.toLowerCase()}>
                    {lang.toUpperCase()}
                  </option>
                ))}
              </select>
            </li>

            {/* Logout Button */}
            <li>
              <button className="logout-button" onClick={handleLogout}>
                <img src={Logout} className="menu-icon4" alt="Logout Icon" />
                <strong>{t("Sign out")}</strong>
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MobileHeader;
