import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/quiz/exam.css";

const API_URL = process.env.REACT_APP_API_URL; // API URL from .env
const API_KEY = process.env.REACT_APP_API_KEY; // API Key from .env

const ExamList = () => {
  const { courseId } = useParams();
  const [exams, setExams] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExams = async () => {
      try {
        if (courseId) {
          const response = await axios.get(
            `${API_URL}/exams/courses/${courseId}`,
            {
              headers: { "API-Key": API_KEY }, // Pass API key in the headers
            }
          );
          setExams(response.data);
        }
      } catch (error) {
        console.error("Error fetching exams:", error);
        alert("Failed to fetch exams.");
      }
    };

    fetchExams();
  }, [courseId]);

  const handleAddExam = () => {
    navigate(`/add-exam/${courseId}`); // Redirect to add exam page
  };

  const handleUpdateExam = (examId) => {
    navigate(`/update-exam/${examId}`); // Redirect to update exam page
  };

  const handleDeleteExam = async (examId) => {
    try {
      await axios.delete(`${API_URL}/exams/${examId}`, {
        headers: { "API-Key": API_KEY }, // Include API Key in the headers for delete request
      });
      setExams(exams.filter((exam) => exam.id !== examId)); // Update state after deletion
      alert("Exam deleted successfully.");
    } catch (error) {
      console.error("Error deleting exam:", error);
      alert("Failed to delete exam.");
    }
  };

  return (
    <div className="exam-list-container">
      <h3>Exam List</h3>
      {/* Buttons Row */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        {/* Return Button */}
        <button
          onClick={() => navigate("/teacher-dashboard")}
          style={{
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            padding: "10px",
            borderRadius: "8px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaArrowLeft style={{ marginRight: "5px" }} /> Return
        </button>
        {/* Add Exam Button */}
        <button
          className="add-exam-button"
          onClick={handleAddExam}
          style={{
            padding: "10px 15px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Add Exam
        </button>
      </div>
      {exams.length > 0 ? (
        <table className="exam-table">
          <thead>
            <tr>
              <th>Exam Name</th>
              <th>Link</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {exams.map((exam) => (
              <tr key={exam.id}>
                <td>{exam.examName}</td>
                <td>
                  <a
                    href={exam.examLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open Exam
                  </a>
                </td>
                <td>
                  <button onClick={() => handleUpdateExam(exam.id)}>
                    Update
                  </button>
                  <button onClick={() => handleDeleteExam(exam.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No exams available.</p>
      )}
    </div>
  );
};

export default ExamList;
