import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaAtom,
  FaBolt,
  FaBookOpen,
  FaBuilding,
  FaCalculator,
  FaCogs,
  FaDollarSign,
  FaFacebook,
  FaFlask,
  FaGlobe,
  FaInstagram,
  FaLanguage,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";

import { Link } from "react-router-dom";
import exam from "../../images/exam.png";
import live from "../../images/live.png";
import logo1 from "../../images/logo1.png";
import quiz from "../../images/quiz.png";
import summary from "../../images/summary.png";
import "../../styles/home/home.css";
<link rel="icon" href="%PUBLIC_URL%/favicon.ico" />;

const Home = () => {
  // State to manage the visibility of the dropdown menu and Google Form
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showForm, setShowForm] = useState(false); // State to toggle form visibility

  // Reference to the dropdown and the login button
  const dropdownRef = useRef(null);
  const loginButtonRef = useRef(null);
  const formRef = useRef(null); // Reference to scroll to the form section

  // Handle click event to toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  // Close the dropdown when clicking outside of it
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !loginButtonRef.current.contains(event.target)
    ) {
      setIsDropdownVisible(false); // Close the dropdown
    }
  };

  // Add event listener when the component mounts
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside); // Clean up on unmount
    };
  }, []);

  const { t, i18n } = useTranslation(); // Destructure t and i18n
  // Language switcher function
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // Function to handle the sign-up button click and show the form
  const handleShowForm = () => {
    setShowForm(true); // Show the form

    // Scroll to the Google Form section smoothly
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      {/* Navigation Bar */}
      <header className="navbar">
        <div className="header-left" style={{ marginLeft: "0.5em" }}>
          <img src={logo1} className="menu-icon6" alt="Graduation Cap Icon" />{" "}
          <p style={{ marginTop: "21px" }}>DZSCHOOL.ONLINE</p>
        </div>
        <div className="nav-actions">
          {/* Language Selection Dropdown */}

          <select
            onChange={(e) => changeLanguage(e.target.value)}
            defaultValue="en"
            className="language-select"
            aria-label="Language Selection"
          >
            {["en", "ar", "fr"].map((lang) => (
              <option key={lang} value={lang.toLowerCase()}>
                {lang.toUpperCase()}
              </option>
            ))}
          </select>

          {/* Login Button */}
          <button
            ref={loginButtonRef} // Reference to the login button
            className="login-btn"
            onClick={toggleDropdown} // Toggle dropdown on click
          >
            {t("Login")}
          </button>

          {/* Login Dropdown Menu */}
          {isDropdownVisible && (
            <ul ref={dropdownRef} className="login-dropdown">
              <li>
                <Link to="/student-login"> {t("Student Login")}</Link>
              </li>
              <li>
                <Link to="/teacher-login"> {t("Teacher Login")}</Link>
              </li>
              {/* <li>
                <Link to="/admin-login">Admin Login</Link>
              </li>
              <li>
                <Link to="/admin-list">Admin List</Link>
              </li> */}
            </ul>
          )}

          {/* Sign-up Button */}
          <Link to="/signup">
            <button className="signup-btn">{t("Sign Up")}</button>
          </Link>
        </div>
      </header>
      {/* Hero Section */}
      <div className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">DZ SCHOOL</h1>
          <p className="hero-description">
            {t("Learn Anytime, Anywhere, and Achieve Your Goals")}
          </p>
          <p className="hero-sub-description">
            {t(
              "Our platform offers interactive lessons, live courses, quizzes, and personalized learning paths to ensure you achieve your academic and personal growth. Whether you're preparing for exams, learning new skills, or enhancing your career, DZ Online School is here to support you every step of the way."
            )}
          </p>
          <Link to="/signup">
            <button className="get-started-btn" onClick={handleShowForm}>
              {t("Get Started Now")}
            </button>
          </Link>
        </div>
      </div>

      {/* Our Learning Platform Section */}
      <div className="platform-section">
        <h2>{t("Our Learning Platform")}</h2>
        <p>
          {t(
            "Join a big number of students now, contact us and let us help you!"
          )}
        </p>
        <Link to="/signup">
          <button className="join-now-btn">{t("Join Now")}</button>
        </Link>

        <div className="platform-details">
          <div className="platform-feature">
            <img src={live} className="menu-icon5" alt="Live Course Icon" />
            <h3> {t("Live Courses")}</h3>
            <p>
              {t("Interactive and real-time lessons with expert instructors.")}
            </p>
          </div>
          <div className="platform-feature">
            <img src={quiz} className="menu-icon5" alt="Quiz Icon" />
            <h3>{t("Quizzes")}</h3>
            <p>
              {t("Test your knowledge with quizzes at the end of each module.")}
            </p>
          </div>
          <div className="platform-feature">
            <img src={exam} className="menu-icon5" alt="Exam Icon" />
            <h3>{t("Exams")}</h3>
            <p>
              {t(
                "Get certified by completing important exams to assess your skills."
              )}
            </p>
          </div>
          <div className="platform-feature">
            <img src={summary} className="menu-icon5" alt="Summary Icon" />
            <h3>{t("Summaries")} </h3>
            <p>
              {t(
                "Access study materials, summaries, and notes to aid in your learning."
              )}
            </p>
          </div>
        </div>
      </div>

      {/* Select Your Level Section */}
      <div className="level-section">
        <h2>{t("Select Your Level")}</h2>

        {/* Short description after h2 */}
        <p className="level-description">
          {t(
            "Choose your level from the following categories to explore courses  tailored for your grade. Select the appropriate grade level to begin your learning journey."
          )}
        </p>

        <div className="level-categories">
          <div className="level-category" style={{ background: "blue" }}>
            <h3 style={{ color: "white" }}> {t("High School")}</h3>
            <ul style={{ color: "white" }}>
              <li>
                <Link to="/signup">{t("Grade 1")}</Link>
              </li>
              <li>
                <Link to="/signup">{t("Grade 2")}</Link>
              </li>
              <li>
                <Link to="/signup">{t("Grade 3")}</Link>
              </li>
            </ul>
          </div>
          <div className="level-category" style={{ background: "green" }}>
            <h3>{t("Middle School")}</h3>
            <ul>
              <li>
                <Link to="/signup">{t("Grade 1")}</Link>
              </li>
              <li>
                <Link to="/signup">{t("Grade 2")}</Link>
              </li>
              <li>
                <Link to="/signup">{t("Grade 3")}</Link>
              </li>
              <li>
                <Link to="/signup">{t("Grade 4")}</Link>
              </li>
            </ul>
          </div>
          <div className="level-category" style={{ background: "purple" }}>
            <h3>{t("Primary School")}</h3>
            <ul>
              <li>
                <Link to="/signup">{t("Grade 1")}</Link>
              </li>
              <li>
                <Link to="/signup">{t("Grade 2")}</Link>
              </li>
              <li>
                <Link to="/signup">{t("Grade 3")}</Link>
              </li>
              <li>
                <Link to="/signup">{t("Grade 4")}</Link>
              </li>
              <li>
                <Link to="/signup">{t("Grade 5")}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Subjects We Cover Section */}
      <div className="subjects-section">
        <h2>{t("Subjects We Cover")}</h2>
        <div className="subjects-list">
          {/* Subject Cards with Colored Icons */}
          <div className="subject-card">
            <FaCalculator size={30} className="icon-math" />
            {/* Icon for Mathematics */}
            <h3>{t("Mathematics")}</h3>
          </div>
          <div className="subject-card">
            <FaFlask size={30} className="icon-science" />
            {/* Icon for Science */}
            <h3>{t("Science")}</h3>
          </div>
          <div className="subject-card">
            <FaLanguage size={30} className="icon-french" />
            {/* Icon for English */}
            <h3>{t("English")}</h3>
          </div>
          <div className="subject-card">
            <FaGlobe size={30} className="icon-history" />
            {/* Icon for History & Geography */}
            <h3>{t("History & Geography")}</h3>
          </div>
          <div className="subject-card">
            <FaAtom size={30} className="icon-physics" />
            {/* Icon for Physics */}
            <h3>{t("Physics")}</h3>
          </div>
          <div className="subject-card">
            <FaLanguage size={30} className="icon-french" />
            {/* Icon for French */}
            <h3>{t("French")}</h3>
          </div>
          <div className="subject-card">
            <FaLanguage size={30} className="icon-french" />
            {/* Icon for Arabic */}
            <h3>{t("Arabic")}</h3>
          </div>
          <div className="subject-card">
            <FaBookOpen size={30} className="icon-english" />
            {/* Icon for Philosophy */}
            <h3>{t("Philosophy")}</h3>
          </div>
          <div className="subject-card">
            <FaBuilding size={30} className="icon-philosophy" />
            {/* Icon for Civil Engineering */}
            <h3>{t("Civil Engineering")}</h3>
          </div>
          <div className="subject-card">
            <FaCogs size={30} className="icon-civil-engineering" />
            {/* Icon for Mechanic */}
            <h3>{t("Mechanic")}</h3>
          </div>
          <div className="subject-card">
            <FaDollarSign size={30} className="icon-mechanic" />
            {/* Icon for Economic */}
            <h3>{t("Economic")}</h3>
          </div>
          <div className="subject-card">
            <FaBolt size={30} className="icon-electricity" />
            {/* Icon for Electricity */}
            <h3>{t("Electricity")}</h3>
          </div>
        </div>
      </div>

      {/* General Learning Section */}
      <div className="general-learning-section">
        <h2>{t("General Learning")}</h2>
        <div className="general-learning-categories">
          <div className="general-learning-category">
            <h3>{t("Spanish")}</h3>
          </div>
          <div className="general-learning-category">
            <h3>{t("English")}</h3>
          </div>
          <div className="general-learning-category">
            <h3>{t("French")}</h3>
          </div>
          <div className="general-learning-category">
            <h3>{t("Italian")}</h3>
          </div>
          <div className="general-learning-category">
            <h3>{t("Programming")}</h3>
          </div>
          <div className="general-learning-category">
            <h3>{t("Trading")}</h3>
          </div>
          <div className="general-learning-category">
            <h3>{t("Marketing")}</h3>
          </div>
          <div className="general-learning-category">
            <h3>{t("Design")}</h3>
          </div>
        </div>
      </div>

      {/* Health Section */}
      <div className="health-section">
        <h2>
          {t("Instructions: Develop Your Mental, Physical, and Family Health")}
        </h2>
        <div className="health-categories">
          <div className="health-category">
            <h3>{t("Family")}</h3>
            <p>
              {t(
                "Tips and guidance on how to nurture and maintain strong family bonds."
              )}
            </p>
          </div>
          <div className="health-category">
            <h3>{t("Education")}</h3>
            <p>{t("Guidelines on improving education and learning.")}</p>
          </div>
          <div className="health-category">
            <h3>{t("Mental & Physical Health")}</h3>
            <p>
              {t(
                "Explore ways to improve your mental and physical well-being through habits, exercises."
              )}
            </p>
          </div>
        </div>
      </div>
      {/* Google Form Embed Section */}
      <div className="google-form-section">
        <h1>{t("Fill Out Our Form")}</h1>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSePiPelc3fLvQ8XZw3F8DwjVw2vELSYGtBJo0fsKS28bhbxLQ/viewform?embedded=true"
          width="640"
          height="1542"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Google Form"
        >
          Loading…
        </iframe>
      </div>
      {/* get in touch */}
      <div className="getintouch" id="getintouch">
        <div
          className="getintouch-container"
          data-aos="zoom-in-down"
          data-aos-duration="1200"
        >
          <h2
            data-aos="zoom-in-down"
            data-aos-duration="1200"
            style={{
              color: "white",
              fontFamily:
                "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
              fontSize: "25px",
              textAlign: "left",
            }}
          >
            <strong>{t("GET IN TOUCH")}</strong>
          </h2>
          <br />
          <br />

          <p
            style={{ marginRight: "50%", fontSize: "large" }}
            data-aos="zoom-in-down"
            data-aos-duration="1200"
          >
            {t(
              "Reach out to us today and take the next step towards achieving your education goals."
            )}
          </p>

          <div
            data-aos="zoom-in-down"
            data-aos-duration="1200"
            className="social-media"
            style={{
              letterSpacing: "50px",
              display: "inline-flex",
              textAlign: "center",
            }}
          >
            <a
              href="https://www.tiktok.com/@dzschool.online?_t=8sL4f5Lxufm&_r=1"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
              style={{
                fontSize: "2rem",
              }}
            >
              <FaTiktok style={{ color: "#ffffff" }} />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61570180882364"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
              style={{ fontSize: "2rem" }}
            >
              <FaFacebook style={{ color: "#ffffff" }} />
            </a>

            <a
              href="https://www.instagram.com/dzschool.online/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
              style={{ fontSize: "2rem" }}
            >
              <FaInstagram style={{ color: "#ffffff" }} />
            </a>

            <a
              href="https://www.youtube.c"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
              style={{ fontSize: "2rem" }}
            >
              <FaYoutube style={{ color: "#ffffff" }} />
            </a>
          </div>
          <br />
          <br />

          <div data-aos="zoom-in-down" data-aos-duration="1200">
            <h3
              data-aos="zoom-in-down"
              data-aos-duration="1200"
              style={{ textAlign: "left", color: "white" }}
            >
              {t("Menu")}
            </h3>
            <h4>{t("Levels")} </h4>
            {t("Primary School")}
            <br />
            {t("Middle School")}
            <br />
            {t("High School")}
            <br />
            {t("General Learning")}
            <br />
            <h4>{t("Grades")}</h4>
            {t("Grade 1")}
            <br />
            {t("Grade 2")}
            <br />
            {t("Grade 3")}
            <br />
            {t("Grade 4")}
            <br />
            {t("Grade 5")}
            <br />
            <h4>{t("Tools")}</h4>
            {t("Live Courses")}
            <br />
            {t("Quizzes")}
            <br />
            {t("Summaries")}
            <br />
            {t("Exercises")}
            <br />
            {t("Exams")}
            <br />
            {t("Advices")}
            <br />
            <h4>{t("Contact")}</h4>
            <p>
              <a
                href="tel:+7859176976"
                style={{ color: "white", textDecoration: "none" }}
                data-aos="zoom-in-down"
                data-aos-duration="1200"
              >
                (785) 917-6976
              </a>
            </p>
            <a
              href="https://app.titan.email/mail/"
              style={{ color: "white", textDecoration: "none" }}
              data-aos="zoom-in-down"
              data-aos-duration="1200"
            >
              support@dzonlineschool.com
            </a>
            <br /> <br />
            <h4>{t("Office Hours")}</h4>
            8:00 AM to 5:00 PM
            <br /> <br />
            <h4>{t("Corporate Office")}</h4>
            Alger
            <br />
            Alger, Algeria
            <br />
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default Home;
