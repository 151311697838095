import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import logo1 from "../../images/logo1.png";
import Logout from "../../images/Logout.png";
import "../../styles/student/StudentList.css";
import AuthService from "../auth/AuthService";
const TeacherList = () => {
  const { adminId } = useParams(); // Get adminId from route params
  const [teachers, setTeachers] = useState([]);
  const [hierarchy, setHierarchy] = useState({});
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  // Language switcher function
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // Get API URL and API Key from environment variables
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  // Fetch teachers based on adminId
  const fetchTeachers = async () => {
    try {
      // Check if the admin is logged in
      const admin = AuthService.getCurrentUser();
      if (!admin) {
        // Redirect to admin login if not authenticated
        navigate("/admin-login");
        return;
      }

      // Fetch teachers data for the given adminId
      const response = await axios.get(`${API_URL}/teachers/admin/${adminId}`, {
        headers: { "API-Key": API_KEY },
      });

      setTeachers(response.data); // Set teachers data to state
      organizeTeachers(response.data); // Organize teachers data by level, grade, and subject
    } catch (error) {
      setError("Failed to fetch teachers");
    }
  };

  // Organize teachers data into a hierarchy based on Level, Grade, and Subject
  const organizeTeachers = (teachers) => {
    const hierarchy = {};

    teachers.forEach((teacher) => {
      const { levelName, gradeName, subjectNames } = teacher;

      if (!hierarchy[levelName]) {
        hierarchy[levelName] = {};
      }

      if (!hierarchy[levelName][gradeName]) {
        hierarchy[levelName][gradeName] = {};
      }

      subjectNames.forEach((subject) => {
        if (!hierarchy[levelName][gradeName][subject]) {
          hierarchy[levelName][gradeName][subject] = [];
        }
        hierarchy[levelName][gradeName][subject].push(teacher);
      });
    });

    setHierarchy(hierarchy);
  };

  useEffect(() => {
    fetchTeachers(); // Fetch teachers when the component mounts or adminId changes
  }, [adminId, navigate]); // Ensure to run when adminId or navigate changes

  // Handle click on a subject to filter teachers based on level, grade, and subject
  const handleSubjectClick = (levelName, gradeName, subjectName) => {
    const teachersForSubject = hierarchy[levelName][gradeName][subjectName];
    setSelectedTeachers(teachersForSubject || []);
    setSelectedLevel(levelName);
    setSelectedGrade(gradeName);
  };

  // Handle delete teacher action
  const handleDelete = async (id) => {
    try {
      // Send DELETE request with dynamic API URL and API Key
      await axios.delete(`${API_URL}/teachers/${id}`, {
        headers: {
          "API-Key": API_KEY, // Include the API Key in the headers
        },
      });

      fetchTeachers(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting teacher:", error);
      setError("Failed to delete teacher. Please try again.");
    }
  };

  // Navigate to teacher details page
  const handleDetails = (id) => {
    navigate(`/teacher-details/${id}`);
  };

  // Navigate to update teacher page
  const handleUpdate = (id) => {
    navigate(`/update-teacher/${adminId}/${id}`); // Pass adminId and teacher ID
  };

  // Navigate to create new teacher page
  const handleAddTeacher = () => {
    navigate(`/create-teacher/${adminId}`);
  };

  // Navigate to the teacher courses page
  const handleCourses = (id) => {
    navigate(`/course-teacher-list/${id}`);
  };

  return (
    <div>
      <header className="desktop-header">
        <div className="header-left" style={{ marginLeft: "0.5em" }}>
          <img src={logo1} className="menu-icon6" alt="Graduation Cap Icon" />{" "}
          <p style={{ marginTop: "21px" }}>DZSCHOOL.ONLINE</p>
        </div>
        <div className="header-right">
          {/* Language Selection Dropdown */}
          <select
            onChange={(e) => changeLanguage(e.target.value)}
            defaultValue="en"
            className="language-select"
            aria-label="Language Selection"
          >
            {["en", "ar", "fr"].map((lang) => (
              <option key={lang} value={lang.toLowerCase()}>
                {lang.toUpperCase()}
              </option>
            ))}
          </select>

          {/* Logout Button */}
          <button
            className="logout-button"
            onClick={async () => {
              try {
                await AuthService.logout(); // Perform logout
                navigate("/home"); // Navigate to login page upon success
              } catch (error) {
                console.error("Logout failed:", error);
                // Optional: Show a message to the user if logout fails
              }
            }}
            aria-label="Sign Out"
          >
            <img src={Logout} className="menu-icon4" alt="Logout Icon" />
            <strong style={{ paddingRight: "4px" }}>{t("Sign out")}</strong>
          </button>
        </div>
      </header>
      <div style={{ display: "flex" }}>
        {/* Sidebar with level, grade, and subject navigation */}
        <aside
          style={{
            width: "20%",
            padding: "10px",
            borderRight: "1px solid #ccc",
          }}
        >
          <h3>Menu</h3>
          <button
            onClick={() => navigate("/admin-dashboard")}
            style={{
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              padding: "10px",
              borderRadius: "20px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaArrowLeft style={{ marginRight: "5px" }} /> Return
          </button>
          {Object.entries(hierarchy).map(([levelName, grades]) => (
            <div key={levelName}>
              <h4>{levelName}</h4>
              {Object.entries(grades).map(([gradeName, subjects]) => (
                <div key={gradeName}>
                  <h5>{gradeName}</h5>
                  {Object.keys(subjects).map((subjectName) => (
                    <div key={subjectName}>
                      <button
                        className="button1"
                        onClick={() =>
                          handleSubjectClick(levelName, gradeName, subjectName)
                        }
                      >
                        {subjectName}
                      </button>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </aside>

        {/* Main Content: List of Teachers */}
        <main style={{ flex: 1, padding: "10px" }}>
          <h2>Teachers List</h2>
          {error && <p className="error">{error}</p>}
          <button onClick={handleAddTeacher}>Add Teacher</button>
          <h3>
            {selectedLevel && selectedGrade
              ? `Selected Level: ${selectedLevel}, Grade: ${selectedGrade}`
              : "All Teachers"}
          </h3>
          <table className="table">
            <thead>
              <tr>
                <th>#</th> {/* Added column for the order */}
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Password</th>
                <th>Level Name</th>
                <th>Grade Name</th>
                <th>Subject Names</th>
                <th>Students Group</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {(selectedTeachers.length > 0 ? selectedTeachers : teachers).map(
                (teacher, index) => (
                  <tr key={teacher.id}>
                    <td>{index + 1}</td> {/* Display the order number */}
                    <td>{teacher.firstName}</td>
                    <td>{teacher.lastName}</td>
                    <td>{teacher.email}</td>
                    <td>{teacher.phoneNumber}</td>
                    <td>{teacher.password}</td>
                    <td>{teacher.levelName}</td>
                    <td>{teacher.gradeName}</td>
                    <td>{teacher.subjectNames.join(", ")}</td>
                    <td>
                      {Array.isArray(teacher.groupStudentsNumber)
                        ? teacher.groupStudentsNumber.join(", ")
                        : teacher.groupStudentsNumber}
                    </td>
                    <td>
                      <button
                        onClick={() => handleDetails(teacher.id)}
                        className="button1"
                      >
                        Details
                      </button>
                      <button
                        onClick={() => handleUpdate(teacher.id)}
                        className="button1"
                      >
                        Update
                      </button>
                      <button
                        onClick={() => handleDelete(teacher.id)}
                        className="button1"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => handleCourses(teacher.id)}
                        className="button1"
                      >
                        Courses
                      </button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>

          {selectedTeachers.length === 0 && teachers.length === 0 && (
            <p>No teachers available.</p>
          )}
        </main>
      </div>{" "}
    </div>
  );
};

export default TeacherList;
