import {
  faFacebook,
  faInstagram,
  faTelegramPlane,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import { faClock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Importing calendar styles
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "../../i18n/i18n";
import MobileHeader from "./MobileHeader";

import billIcon from "../../images/billIcon.png";
import Books from "../../images/Books.png";
import calendar from "../../images/calendar.png";
import contactIcon from "../../images/contactIcon.png";
import exam from "../../images/exam.png";
import GraduationIcon from "../../images/Graduation3.png";
import home from "../../images/home.png";
import live from "../../images/live.png";
import logo1 from "../../images/logo1.png";
import Logout from "../../images/Logout.png";
import mail from "../../images/mail.png";
import femalepic from "../../images/profilepic/femalepic.jpg";
import femalepic16 from "../../images/profilepic/femalepic16.jpg";
import malepic from "../../images/profilepic/malepic.jpg";
import malepic16 from "../../images/profilepic/malepic16.jpg";
import quiz from "../../images/quiz.png";
import student from "../../images/student.png";
import civil from "../../images/subjects/civil.png";
import economy from "../../images/subjects/economy.png";
import electric from "../../images/subjects/electric.png";
import videoicon from "../../images/videoicon.png";

import {
  default as english,
  default as french,
} from "../../images/subjects/language.png";
import math from "../../images/subjects/math.png";
import mechanic from "../../images/subjects/mechanic.png";
import physics from "../../images/subjects/physics.png";
import science from "../../images/subjects/science.png";
import summary from "../../images/summary.png";
import "../../styles/student/studentprofile.css";
import AuthService from "../auth/AuthService";

const StudentProfile = () => {
  const [studentData, setStudentData] = useState(null);
  const [coursesData, setCoursesData] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [quizzesData, setQuizzesData] = useState([]);
  const [examsData, setExamsData] = useState([]);
  const [summariesData, setSummariesData] = useState([]);
  const [videosData, setVideosData] = useState([]);
  const [inboxData, setInboxData] = useState([]);
  const [billsData, setBillsData] = useState([]);
  const [mediaData, setMediaData] = useState([]);

  const [activeTab, setActiveTab] = useState("home");

  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); // Destructure t and i18n

  const API_URL = process.env.REACT_APP_API_URL; // Your API URL
  const API_KEY = process.env.REACT_APP_API_KEY; // From .env file

  // Language switcher function
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const student = AuthService.getCurrentUser();
        if (!student) {
          navigate("/student-login");
          return;
        }

        setStudentData(student);

        // Fetch courses data with the corrected API URL and API key in headers
        const response = await axios.get(
          `${API_URL}/courses/student/${student.id}`,
          {
            headers: { "API-Key": API_KEY },
          }
        );

        // Log the response data
        console.log("Fetched courses data:", response.data);
        setCoursesData(response.data);
      } catch (error) {
        // Log any error that occurs during the fetch
        console.error("Error fetching courses data:", error);
      }
    };

    // Call the fetchCourses function
    fetchCourses();
  }, [navigate]);

  // Get unique upcoming course dates function (same as previous example)
  const getUniqueUpcomingCourseDates = () => {
    const today = new Date();
    const uniqueCourseDates = new Set();

    coursesData.forEach((course) => {
      course.courseDays.forEach((day) => {
        let nextDate = new Date(today);
        while (nextDate.toLocaleString("en-US", { weekday: "long" }) !== day) {
          nextDate.setDate(nextDate.getDate() + 1);
        }
        if (nextDate > today) {
          uniqueCourseDates.add(nextDate.toDateString());
        }
      });
    });
    return Array.from(uniqueCourseDates).map((dateStr) => new Date(dateStr));
  };

  const uniqueUpcomingCourseDates = useMemo(getUniqueUpcomingCourseDates, [
    coursesData,
  ]);

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const today = new Date();
      if (date.toDateString() === today.toDateString()) {
        return "today-date";
      }
      if (
        uniqueUpcomingCourseDates.some(
          (courseDate) => courseDate.toDateString() === date.toDateString()
        )
      ) {
        return "course-day";
      }
    }
    return null;
  };

  // Extract subjects with their related days and times
  const subjectsWithDetails = useMemo(() => {
    const subjectMap = {};

    coursesData.forEach((course) => {
      const { subjectName, courseDays, courseTime } = course;
      if (!subjectMap[subjectName]) {
        subjectMap[subjectName] = {
          days: new Set(),
          times: new Set(),
        };
      }
      courseDays.forEach((day) => subjectMap[subjectName].days.add(day));
      subjectMap[subjectName].times.add(courseTime);
    });

    return Object.entries(subjectMap).map(([subject, details]) => ({
      subject,
      days: Array.from(details.days).join(", "),
      time: Array.from(details.times).join(", "),
    }));
  }, [coursesData]);

  // Function to fetch quizzes for a course
  const fetchQuizzesForCourse = (courseId) => {
    console.log("Fetching quizzes for courseId:", courseId);

    const selectedCourse = coursesData.find((course) => course.id === courseId);

    if (!selectedCourse) {
      console.error("Course not found for courseId:", courseId);
      return;
    }

    const { levelName, gradeName, subjectName, groupStudentsNumber } =
      selectedCourse;

    console.log("Selected course parameters:", {
      levelName,
      gradeName,
      subjectName,
      groupStudentsNumber,
    });

    axios
      .get(`${API_URL}/quizzes/levelgradesubjectgroup`, {
        params: {
          levelName,
          gradeName,
          subjectName,
          groupStudentsNumber,
        },
        headers: { "API-Key": API_KEY }, // Include API key in headers
      })
      .then((response) => {
        console.log("Fetched quizzes data:", response.data);
        setQuizzesData(response.data);
        setActiveTab("quizzes");
      })
      .catch((error) => {
        console.error("Error fetching quizzes data:", error);
      });
  };

  // Function to fetch exams for a course
  const fetchExamsForCourse = (courseId) => {
    console.log("Fetching exams for courseId:", courseId);

    const selectedCourse = coursesData.find((course) => course.id === courseId);

    if (!selectedCourse) {
      console.error("Course not found for courseId:", courseId);
      return;
    }

    const { levelName, gradeName, subjectName, groupStudentsNumber } =
      selectedCourse;

    console.log("Selected course parameters:", {
      levelName,
      gradeName,
      subjectName,
      groupStudentsNumber,
    });

    axios
      .get(`${API_URL}/exams/levelgradesubjectgroup`, {
        params: {
          levelName,
          gradeName,
          subjectName,
          groupStudentsNumber,
        },
        headers: { "API-Key": API_KEY }, // Include API key in headers
      })
      .then((response) => {
        console.log("Fetched exams data:", response.data);
        setExamsData(response.data);
        setActiveTab("exams");
      })
      .catch((error) => {
        console.error("Error fetching exams data:", error);
      });
  };

  // Function to fetch summaries for a course
  const fetchSummariesForCourse = (courseId) => {
    const selectedCourse = coursesData.find((course) => course.id === courseId);

    if (!selectedCourse) return;

    const { levelName, gradeName, subjectName, groupStudentsNumber } =
      selectedCourse;

    axios
      .get(`${API_URL}/summaries/levelgradesubjectgroup`, {
        params: {
          levelName,
          gradeName,
          subjectName,
          groupStudentsNumber,
        },
        headers: { "API-Key": API_KEY }, // Include API key in headers
      })
      .then((response) => {
        setSummariesData(response.data);
        setActiveTab("summaries");
      })
      .catch((error) => console.error("Error fetching summaries data:", error));
  };

  // Function to fetch videos for a course
  const fetchVideosForCourse = (courseId) => {
    console.log("Fetching videos for courseId:", courseId);

    const selectedCourse = coursesData.find((course) => course.id === courseId);

    if (!selectedCourse) {
      console.error("Course not found for courseId:", courseId);
      return;
    }

    const { levelName, gradeName, subjectName, groupStudentsNumber } =
      selectedCourse;

    console.log("Selected course parameters:", {
      levelName,
      gradeName,
      subjectName,
      groupStudentsNumber,
    });

    axios
      .get(`${API_URL}/videos/levelgradesubjectgroup`, {
        params: {
          levelName,
          gradeName,
          subjectName,
          groupStudentsNumber,
        },
        headers: { "API-Key": API_KEY }, // Include API key in headers
      })
      .then((response) => {
        console.log("Fetched videos data:", response.data);
        setVideosData(response.data);
        setActiveTab("videos");
      })
      .catch((error) => {
        console.error("Error fetching Videos data:", error);
      });
  };
  const renderStudentInfo = () => {
    if (!studentData) {
      return <div>Loading student information...</div>;
    }
    return (
      <div>
        <div
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          {/* Return Button */}
          <button
            onClick={() => setActiveTab("home")}
            style={{
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              padding: "5px 10px",
              borderRadius: "20px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaArrowLeft style={{ marginRight: "5px" }} /> {t("Return")}
          </button>

          {/* Heading */}
          <div style={{ flex: 1, textAlign: "center" }}>
            <h3 style={{ margin: 0 }}>{t("Student Profile")}</h3>
          </div>
        </div>
        <br />
        <br />

        <p>
          <strong>{t("First Name")}:</strong> {studentData.firstName}
        </p>
        <p>
          <strong>Last Name:</strong> {studentData.lastName}
        </p>
        <p>
          <strong>Email:</strong> {studentData.email}
        </p>
        <p>
          <strong>Phone Number:</strong> {studentData.phoneNumber}
        </p>
        <p>
          <strong>Gender:</strong> {studentData.gender}
        </p>
        <p>
          <strong>Age:</strong> {studentData.age}
        </p>
        <p>
          <strong>State:</strong> {studentData.state}
        </p>
        <p>
          <strong>City:</strong> {studentData.city}
        </p>
        <p>
          <strong>Level:</strong> {studentData.levelName}
        </p>
        <p>
          <strong>Grade:</strong> {studentData.gradeName}
        </p>
        <p>
          <strong>Subjects:</strong> {studentData.subjectNames.join(", ")}
        </p>
        <p>
          <strong>Date of Join:</strong>{" "}
          {new Date(studentData.dateOfJoin).toLocaleDateString()}
        </p>
      </div>
    );
  };

  const renderCourseOverviewSquares = () => (
    // <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
    <div className="course-card3">
      {coursesData.map((course) => {
        let courseHeaderStyle = {};

        // Set background image based on the subject name
        if (course.subjectName === "SCIENCE") {
          courseHeaderStyle = {
            backgroundImage: `url(${science})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            color: "white",
            zIndex: "2",
            height: "50vh",
          };
        } else if (course.subjectName === "MATHEMATICS") {
          courseHeaderStyle = {
            backgroundImage: `url(${math})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            zIndex: "2",
            height: "50vh",
          };
          // Optional media query for smaller screens
          if (window.innerWidth < 767) {
            courseHeaderStyle.fontSize = "5vw";
          }
        } else if (course.subjectName === "ENGLISH") {
          courseHeaderStyle = {
            backgroundImage: `url(${english})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            zIndex: "2",
            height: "50vh",
          };
        } else if (course.subjectName === "FRENCH") {
          courseHeaderStyle = {
            backgroundImage: `url(${french})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            zIndex: "2",
            height: "50vh",
          };
        } else if (course.subjectName === "ECONOMY") {
          courseHeaderStyle = {
            backgroundImage: `url(${economy})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            zIndex: "2",
            height: "50vh",
          };
        } else if (course.subjectName === "TRADING") {
          courseHeaderStyle = {
            backgroundImage: `url(${economy})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            zIndex: "2",
            height: "50vh",
          };
        } else if (course.subjectName === "PHYSICS") {
          courseHeaderStyle = {
            backgroundImage: `url(${physics})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            zIndex: "2",
            height: "50vh",
          };
        } else if (course.subjectName === "CIVIL ENGINEERING") {
          courseHeaderStyle = {
            backgroundImage: `url(${civil})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            zIndex: "2",
            height: "50vh",
          };
          if (window.innerWidth < 767) {
            courseHeaderStyle.fontSize = "5vw";
          }
        } else if (course.subjectName === "MECHANIC") {
          courseHeaderStyle = {
            backgroundImage: `url(${mechanic})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            zIndex: "2",
            height: "50vh",
          };
        } else if (course.subjectName === "ELECTRICITY") {
          courseHeaderStyle = {
            backgroundImage: `url(${electric})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            zIndex: "2",
            height: "50vh",
          };
          if (window.innerWidth < 767) {
            courseHeaderStyle.fontSize = "5vw";
          }
        }

        // Get the current date
        const currentDate = new Date();

        // Function to highlight the closest days related to the course schedule
        const tileClassName = ({ date, view }) => {
          const courseDays = course.courseDays || []; // Default to empty array if not defined
          const dayOfWeek = date.getDay(); // Get the day of the week (0 - Sunday, 6 - Saturday)

          if (view === "month") {
            // Only highlight future days
            if (date >= currentDate) {
              const dayMap = {
                Sunday: 0,
                Monday: 1,
                Tuesday: 2,
                Wednesday: 3,
                Thursday: 4,
                Friday: 5,
                Saturday: 6,
              };

              // Check if this day of the week matches any of the course's days
              const upcomingDays = getUpcomingDays();
              for (let i = 0; i < upcomingDays.length; i++) {
                if (
                  date.toLocaleDateString() ===
                  upcomingDays[i].date.toLocaleDateString()
                ) {
                  return "highlighted";
                }
              }
            }
          }
          return null;
        };

        // Function to get the upcoming days sorted by date
        const getUpcomingDays = () => {
          const upcomingDays = [];
          const courseDays = course.courseDays || [];
          const dayMap = {
            Sunday: 0,
            Monday: 1,
            Tuesday: 2,
            Wednesday: 3,
            Thursday: 4,
            Friday: 5,
            Saturday: 6,
          };

          // Iterate over the course days and find the closest upcoming ones
          for (let i = 1; i <= 7; i++) {
            // Look for the next 7 days
            const nextDate = new Date(currentDate);
            nextDate.setDate(currentDate.getDate() + i);
            const dayOfWeek = nextDate.getDay();

            if (courseDays.some((day) => dayMap[day] === dayOfWeek)) {
              upcomingDays.push({ date: nextDate, dayOfWeek });
            }
          }

          // Sort by the closest upcoming days
          upcomingDays.sort((a, b) => a.date - b.date);

          return upcomingDays;
        };

        // Get the upcoming days and display them
        const upcomingDays = getUpcomingDays();

        return (
          <div
            key={course.id}
            className="course-card"
            style={{ width: "100%" }}
          >
            <div className="course-header" style={courseHeaderStyle}>
              <p className="course-header3" style={{ color: "white" }}>
                {course.subjectName}
              </p>
            </div>
            <button
              className="course-button"
              style={{ width: "100%", marginTop: "15px", borderRadius: "20px" }}
            >
              <a
                style={{ textDecoration: "none", color: "white" }}
                href={course.liveCourseLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={live} className="menu-icon5" alt="Live Course Icon" />
                <p style={{ textAlign: "center", margin: 0, color: "black" }}>
                  {t("Live Course")}
                </p>
              </a>
            </button>
            <div className="course-content">
              <div className="course-details">
                <p>
                  <strong>{t("Course time")} </strong>
                  <FontAwesomeIcon
                    icon={faClock}
                    style={{ marginRight: "5px" }}
                  />
                  {course.courseTime}
                </p>

                {/* Render Calendar and highlight the course days */}
                <Calendar
                  tileClassName={tileClassName}
                  className="courseCalendar"
                />
              </div>
              <div className="course-buttons">
                <button
                  className="course-button"
                  onClick={() => fetchSummariesForCourse(course.id)}
                >
                  <img
                    src={summary}
                    className="menu-icon5"
                    alt="Summary Icon"
                  />
                  <p style={{ textAlign: "center", margin: 0 }}>
                    {" "}
                    {t("Summaries")}
                  </p>
                </button>

                <button
                  className="course-button"
                  onClick={() => fetchQuizzesForCourse(course.id)}
                >
                  <img src={quiz} className="menu-icon5" alt="Quiz Icon" />
                  <p style={{ textAlign: "center", margin: 0 }}>
                    {t("Quizzes")}
                  </p>
                </button>

                <button
                  className="course-button"
                  onClick={() => fetchExamsForCourse(course.id)}
                >
                  <img src={exam} className="menu-icon5" alt="Exam Icon" />
                  <p style={{ textAlign: "center", margin: 0 }}>
                    {" "}
                    {t("Exams")}
                  </p>
                </button>
                <button
                  className="course-button"
                  onClick={() => fetchVideosForCourse(course.id)}
                >
                  <img
                    src={videoicon}
                    className="menu-icon5"
                    alt="video Icon"
                    style={{ width: "85px", height: "60px" }}
                  />
                  <p style={{ textAlign: "center", margin: 0 }}>
                    {t("Videos")}
                  </p>
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  const renderCoursesList = () => (
    <div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        {/* Return Button */}
        <button
          onClick={() => setActiveTab("home")}
          style={{
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            padding: "5px 10px",
            borderRadius: "20px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaArrowLeft style={{ marginRight: "5px" }} /> {t("Return")}
        </button>

        {/* Heading */}
        <div style={{ flex: 1, textAlign: "center" }}>
          <h3>{t("Courses")}</h3>
        </div>
      </div>

      <div className="courses-list">
        {coursesData.map((course) => {
          // Determine the background image based on the subject name
          const courseHeaderStyle = {
            backgroundImage:
              course.subjectName === "SCIENCE"
                ? `url(${science})`
                : course.subjectName === "MATHEMATICS"
                ? `url(${math})`
                : course.subjectName === "ENGLISH"
                ? `url(${english})` // English background image
                : course.subjectName === "FRENCH"
                ? `url(${french})` // French background image
                : course.subjectName === "ECONOMY"
                ? `url(${economy})` // Economy background image
                : course.subjectName === "TRADING"
                ? `url(${economy})` // Economy background image
                : course.subjectName === "PHYSICS"
                ? `url(${physics})` // Physics background image
                : course.subjectName === "CIVIL ENGINEERING"
                ? `url(${civil})` // Civil Engineering background image
                : course.subjectName === "MECHANIC"
                ? `url(${mechanic})` // Mechanic background image
                : course.subjectName === "ELECTRICITY"
                ? `url(${electric})` // Electricity background image
                : "none", // Default to no background
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "10px",
            color: "white",
          };

          return (
            <div className="generalcard">
              <div key={course.id} className="course-card1">
                <div className="course-header1" style={courseHeaderStyle}>
                  <p className="course-header2" style={{ color: "white" }}>
                    {course.subjectName}
                  </p>
                </div>

                <div className="course-details1">
                  <p>
                    <strong>Level:</strong> {course.levelName}
                  </p>
                  <p>
                    <strong>Grade:</strong> {course.gradeName}
                  </p>
                  <p>
                    <strong>Group:</strong> {course.groupStudentsNumber}
                  </p>
                  <p>
                    <strong>Teacher:</strong> {course.teacherFirstName}{" "}
                    {course.teacherLastName}
                  </p>
                  <p>
                    <strong>Live Course Link:</strong>
                    <a
                      href={course.liveCourseLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="course-link"
                    >
                      {course.liveCourseLink ? "Join Live Course" : "N/A"}
                    </a>
                  </p>
                  <p>
                    <strong>Course Days:</strong> {course.courseDays.join(", ")}
                  </p>
                  <p>
                    <strong>Course Time:</strong> {course.courseTime}
                  </p>
                </div>
                <div className="course-buttons1">
                  <button
                    className="course-button1"
                    onClick={() => fetchQuizzesForCourse(course.id)}
                  >
                    {t("View Quizzes")}
                  </button>
                  <button
                    className="course-button1"
                    onClick={() => fetchExamsForCourse(course.id)}
                  >
                    {t("View Exams")}
                  </button>
                  <button
                    className="course-button1"
                    onClick={() => fetchSummariesForCourse(course.id)}
                  >
                    {t("View Summaries")}
                  </button>
                  <button
                    className="course-button1"
                    onClick={() => fetchVideosForCourse(course.id)}
                  >
                    {t("View Videos")}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  const renderQuizzesList = () => (
    <div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        {/* Return Button */}
        <button
          onClick={() => setActiveTab("home")}
          style={{
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            padding: "5px 10px",
            borderRadius: "20px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaArrowLeft style={{ marginRight: "5px" }} /> {t("Return")}
        </button>

        {/* Heading */}
        <div style={{ flex: 1, textAlign: "center" }}>
          <h3 style={{ margin: 0 }}>{t("Quizzes")}</h3>
        </div>
      </div>

      <div className="quizzesList">
        {quizzesData.map((quiz) => (
          <div key={quiz.id} className="quiz-card">
            <a
              href={quiz.quizLink}
              target="_blank"
              rel="noopener noreferrer"
              className="quiz-link"
            >
              {quiz.quizName}
            </a>
          </div>
        ))}
      </div>
    </div>
  );

  const renderExamsList = () => (
    <div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        {/* Return Button */}
        <button
          onClick={() => setActiveTab("home")}
          style={{
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            padding: "5px 10px",
            borderRadius: "20px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaArrowLeft style={{ marginRight: "5px" }} /> {t("Return")}
        </button>

        {/* Heading */}
        <div style={{ flex: 1, textAlign: "center" }}>
          <h3 style={{ margin: 0 }}>{t("Exams")}</h3>
        </div>
      </div>
      <div className="quizzesList">
        {examsData.map((exam) => (
          <div key={exam.id} className="quiz-card">
            <a
              href={exam.examLink}
              target="_blank"
              rel="noopener noreferrer"
              className="quiz-link"
            >
              {exam.examName}
            </a>
          </div>
        ))}
      </div>
    </div>
  );

  const renderSummariesList = () => (
    <div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        {/* Return Button */}
        <button
          onClick={() => setActiveTab("home")}
          style={{
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            padding: "5px 10px",
            borderRadius: "20px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaArrowLeft style={{ marginRight: "5px" }} /> {t("Return")}
        </button>

        {/* Heading */}
        <div style={{ flex: 1, textAlign: "center" }}>
          <h3 style={{ margin: 0 }}>{t("Summaries")}</h3>
        </div>
      </div>
      <div className="quizzesList">
        {summariesData.map((summary) => (
          <div key={summary.id} className="quiz-card">
            <a
              href={summary.summaryLink}
              target="_blank"
              rel="noopener noreferrer"
              className="quiz-link"
            >
              {summary.summaryName}
            </a>
          </div>
        ))}
      </div>
    </div>
  );

  const renderVideosList = () => (
    <div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        {/* Return Button */}
        <button
          onClick={() => setActiveTab("home")}
          style={{
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            padding: "5px 10px",
            borderRadius: "20px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaArrowLeft style={{ marginRight: "5px" }} /> {t("Return")}
        </button>

        {/* Heading */}
        <div style={{ flex: 1, textAlign: "center" }}>
          <h3 style={{ margin: 0 }}>{t("Videos")}</h3>
        </div>
      </div>
      <div className="quizzesList">
        {videosData.map((video) => (
          <div key={video.id} className="quiz-card">
            <a
              href={video.videoLink}
              target="_blank"
              rel="noopener noreferrer"
              className="quiz-link"
            >
              {video.videoName}
            </a>
          </div>
        ))}
      </div>
    </div>
  );

  const renderInbox = () => {
    return (
      <div>
        <div
          style={{
            // display: "flex",

            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          {/* Return Button */}
          <button
            onClick={() => setActiveTab("home")}
            style={{
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              padding: "5px 10px",
              borderRadius: "20px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaArrowLeft style={{ marginRight: "5px" }} /> {t("Return")}
          </button>

          {/* Heading */}
          <div style={{ textAlign: "center" }}>
            {/* <div style={{ flex: 1, textAlign: "center" }}> */}
            <h3 style={{ margin: 0 }}>{t("Inbox")}</h3>
          </div>
        </div>

        {studentData.inbox ? (
          <p className="quizzesList">
            {studentData.inbox.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </p> // Show the inbox message with line breaks
        ) : (
          <p>Your inbox is empty.</p> // Message when inbox is empty
        )}
      </div>
    );
  };

  const renderBills = () => {
    return (
      <div>
        <div
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          {/* Return Button */}
          <button
            onClick={() => setActiveTab("home")}
            style={{
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              padding: "5px 10px",
              borderRadius: "20px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaArrowLeft style={{ marginRight: "5px" }} /> {t("Return")}
          </button>

          {/* Heading */}
          <div style={{ flex: 1, textAlign: "center" }}>
            <h3>{t("Bills")}</h3>
            <p>
              {t("Use the following number")} {t("and make your next payment")}
              <strong style={{ color: "blue" }}>
                {" "}
                <br />
                BARIDIMOB:{" "}
              </strong>{" "}
              00799999002699096501 <br />
              <strong style={{ color: "blue" }}> CCP: </strong> 0026990965{" "}
              <strong style={{ color: "blue" }}> CLE: </strong> 01 <br />
            </p>
          </div>
        </div>

        {studentData.bills ? (
          <p className="quizzesList">
            {studentData.bills.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </p> // Show the bills message with line breaks
        ) : (
          <p>No bills to display.</p> // Message when there are no bills
        )}
      </div>
    );
  };

  const renderContact = () => {
    return (
      <div>
        {/* Existing Contact Information Section */}
        <div
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          {/* Return Button */}
          <button
            onClick={() => setActiveTab("home")}
            style={{
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              padding: "5px 10px",
              borderRadius: "20px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaArrowLeft style={{ marginRight: "5px" }} /> {t("Return")}
          </button>

          {/* Heading */}
          <div style={{ flex: 1, textAlign: "center" }}>
            <h3>{t("Contact Information")}</h3>
          </div>
        </div>
        <br />
        <br />

        <ul>
          <li>
            <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: "8px" }} />
            <a
              href="https://wa.me/+17859176976"
              style={{ textDecoration: "none" }}
            >
              {" "}
              WhatsApp
            </a>
          </li>
          <li>
            <FontAwesomeIcon
              icon={faTelegramPlane}
              style={{ marginRight: "8px" }}
            />
            <a href="https://t.me/nourbrara" style={{ textDecoration: "none" }}>
              Telegram
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFacebook} style={{ marginRight: "8px" }} />
            <a
              href="https://www.facebook.com/profile.php?id=61570180882364"
              style={{ textDecoration: "none" }}
            >
              Facebook
            </a>
          </li>
          <li>
            <FontAwesomeIcon
              icon={faInstagram}
              style={{ marginRight: "8px" }}
            />
            <a
              href="https://www.instagram.com/dzschool.online/"
              style={{ textDecoration: "none" }}
            >
              Instagram
            </a>
          </li>

          <li>
            <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: "8px" }} />
            Email: <a href="mailto:support@example.com">support@dzschool.com</a>
          </li>
        </ul>

        {/* New "Get in touch with your teacher" Section */}
        <div style={{ border: "1px solid gray", borderRadius: "20px" }}>
          <h3>{t("Get in touch with teacher")}</h3>{" "}
          {/* Dynamic title translation */}
          {studentData && studentData.inbox ? (
            <p style={{ textAlign: "center" }}>
              {/* Safely check if media is a string, then display as a link */}
              {studentData.media && typeof studentData.media === "string" ? (
                studentData.media.split("\n").map((line, index) => {
                  const trimmedLine = line.trim();

                  // Check if the line is a valid URL to render it as a link
                  const isUrl = /^https?:\/\//.test(trimmedLine);

                  return (
                    <span key={index}>
                      {isUrl ? (
                        // If it's a URL, render it as a clickable link
                        <a
                          href={trimmedLine}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {trimmedLine}
                        </a>
                      ) : (
                        trimmedLine
                      )}
                      <br />{" "}
                      {/* This will insert line breaks for each new line */}
                    </span>
                  );
                })
              ) : (
                <p>No media content available.</p> // Message when media is not a string
              )}
            </p>
          ) : (
            <p>Your social media is empty.</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <style>
        {`
          .today-date {
            background-color: #007bff !important;
            color: white !important;
            border-radius: 50%;
          }
          .course-day {
            background-color: #4CAF50 !important;
            color: white !important;
            border-radius: 50%;
          }
          .action-button {
            margin-right: 5px;
          }
        `}
      </style>
      {/* Header desktop */}
      <header className="desktop-header">
        <div className="header-left" style={{ marginLeft: "0.5em" }}>
          <img src={logo1} className="menu-icon6" alt="Graduation Cap Icon" />{" "}
          <p style={{ marginTop: "21px" }}>DZSCHOOL.ONLINE</p>
        </div>
        <div className="header-right">
          {/* Language Selection Dropdown */}
          <select
            onChange={(e) => changeLanguage(e.target.value)}
            defaultValue="en"
            className="language-select"
            aria-label="Language Selection"
          >
            {["en", "ar", "fr"].map((lang) => (
              <option key={lang} value={lang.toLowerCase()}>
                {lang.toUpperCase()}
              </option>
            ))}
          </select>

          {/* Logout Button */}
          <button
            className="logout-button"
            onClick={async () => {
              try {
                await AuthService.logout(); // Perform logout
                navigate("/home"); // Navigate to login page upon success
              } catch (error) {
                console.error("Logout failed:", error);
                // Optional: Show a message to the user if logout fails
              }
            }}
            aria-label="Sign Out"
          >
            <img src={Logout} className="menu-icon4" alt="Logout Icon" />
            <strong style={{ paddingRight: "4px" }}>{t("Sign out")}</strong>
          </button>
        </div>
      </header>

      {/* Header mobile */}
      <header className="mobile-header">
        {/* Pass setActiveTab to MobileHeader to allow navigation */}
        <MobileHeader setActiveTab={setActiveTab} />
      </header>
      <div className="picturemobile">
        <div
          className="profile-circle"
          style={{ textAlign: "center", border: "none" }}
        >
          {studentData && (
            <div
              style={{
                width: "30%",
                height: "110px",
                borderRadius: "90%",
                backgroundImage: `url(${
                  studentData.gender.toLowerCase() === "female"
                    ? studentData.age < 16
                      ? femalepic // Female and age < 16
                      : femalepic16 // Female and age > 15
                    : studentData.age < 16
                    ? malepic // Male and age < 16
                    : malepic16 // Male and age > 15
                })`,
                backgroundSize: "cover", // Ensure the image covers the whole circle
                backgroundPosition: "center", // Center the image within the circle
                color: "white", // Set text color to white (for contrast)
                display: "flex", // Use flexbox for centering text
                alignItems: "center", // Vertically center the text
                justifyContent: "center", // Horizontally center the text
                fontSize: "27px", // Set the font size of initials
                fontWeight: "bold", // Make initials bold
                margin: "0 auto", // Center the circle in its container
                border: "2px solid #fff", // Optional: Add a border around the circle for contrast
              }}
            >
              {/* Display initials */}
              {/* {studentData.firstName.charAt(0)}{studentData.lastName.charAt(0)} */}
            </div>
          )}
          <div
            style={{
              marginTop: "2px",
              fontWeight: "bold",
              fontSize: "1.5em",
            }}
          >
            {studentData ? (
              <>
                {studentData.firstName} {/* Display student's first name */}
                {/* {studentData.lastName.charAt(0)} */}
              </>
            ) : (
              "Loading..." // Display loading message if studentData is unavailable
            )}
          </div>
        </div>
      </div>
      {/* Sidebar */}
      <div style={{ display: "flex", flex: 1 }}>
        <div className="sidebar" style={{ boxShadow: "none", border: "none" }}>
          <div
            className="profile-circle"
            style={{ textAlign: "center", border: "none" }}
          >
            {studentData && (
              <div
                style={{
                  width: "45%",
                  height: "90px",
                  borderRadius: "90%",
                  backgroundImage: `url(${
                    studentData.gender.toLowerCase() === "female"
                      ? studentData.age < 16
                        ? femalepic // Female and age < 16
                        : femalepic16 // Female and age > 15
                      : studentData.age < 16
                      ? malepic // Male and age < 16
                      : malepic16 // Male and age > 15
                  })`,
                  backgroundSize: "cover", // Ensure the image covers the whole circle
                  backgroundPosition: "center", // Center the image within the circle
                  color: "white", // Set text color to white (for contrast)
                  display: "flex", // Use flexbox for centering text
                  alignItems: "center", // Vertically center the text
                  justifyContent: "center", // Horizontally center the text
                  fontSize: "27px", // Set the font size of initials
                  fontWeight: "bold", // Make initials bold
                  margin: "0 auto", // Center the circle in its container
                  border: "2px solid #fff", // Optional: Add a border around the circle for contrast
                }}
              >
                {/* Display initials */}
                {/* {studentData.firstName.charAt(0)}{studentData.lastName.charAt(0)} */}
              </div>
            )}
            <div
              style={{
                marginTop: "2px",
                fontWeight: "bold",
                fontSize: "1.5em",
              }}
            >
              {studentData ? (
                <>
                  {studentData.firstName} {/* Display student's first name */}
                  {/* {studentData.lastName.charAt(0)} */}
                </>
              ) : (
                "Loading..." // Display loading message if studentData is unavailable
              )}
            </div>
          </div>

          <ul>
            <div className="sidebardiv">
              <li
                onClick={() => setActiveTab("home")}
                style={{
                  textDecoration: "none",
                  backgroundColor: "#007bff",
                  color: "white",
                }}
                className="home"
              >
                <img src={home} className="menu-icon" alt="Home Icon" />
                {t("Home")}
              </li>
              <li
                onClick={() => setActiveTab("calendar")}
                style={{ textDecoration: "none" }}
              >
                <img src={calendar} className="menu-icon" alt="Calendar Icon" />
                {t("Calendar")}
              </li>
            </div>
            <div>
              <li
                onClick={() => {
                  setActiveTab("courses");
                  setSelectedCourseId(null);
                }}
                style={{ textDecoration: "none" }}
              >
                <img
                  src={GraduationIcon}
                  className="menu-icon"
                  alt="Courses Icon"
                />
                {t("Courses")}
              </li>
              <li
                onClick={() => setActiveTab("student")}
                style={{ textDecoration: "none" }}
              >
                <img src={student} className="menu-icon" alt="Student Icon" />
                {t("Student Info")}
              </li>
            </div>
            <div style={{ textDecoration: "none", border: "none" }}>
              <li
                onClick={() => setActiveTab("inbox")}
                style={{ textDecoration: "none" }}
              >
                <img src={mail} className="menu-icon" alt="Inbox Icon" />
                {t("Inbox")}
              </li>
              <li
                onClick={() => setActiveTab("bills")}
                style={{ textDecoration: "none" }}
              >
                <img src={billIcon} className="menu-icon" alt="Bill Icon" />
                {t("Bills")}
              </li>
              <li onClick={() => setActiveTab("contact")}>
                <img
                  src={contactIcon}
                  className="menu-icon"
                  alt="Contact Icon"
                />
                {t("Contact")}
              </li>
            </div>
          </ul>
        </div>
        {/* Content */}
        <div className="content">
          {activeTab === "home" && (
            <div>
              <h3
                className="courses-title"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img src={Books} className="menu-icon3" alt="Graduation Icon" />
                {t("My Courses")}
              </h3>
              <div> {renderCourseOverviewSquares()}</div>
            </div>
          )}
          {activeTab === "student" && renderStudentInfo()}
          {activeTab === "courses" && renderCoursesList()}
          {activeTab === "quizzes" && renderQuizzesList()}
          {activeTab === "exams" && renderExamsList()}
          {activeTab === "summaries" && renderSummariesList()}
          {activeTab === "videos" && renderVideosList()}
          {activeTab === "calendar" && (
            <div>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                {/* Return Button */}
                <button
                  onClick={() => setActiveTab("home")}
                  style={{
                    backgroundColor: "#007bff",
                    color: "white",
                    border: "none",
                    padding: "5px 10px",
                    borderRadius: "20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FaArrowLeft style={{ marginRight: "5px" }} /> {t("Return")}
                </button>

                {/* Heading */}
                <div style={{ flex: 1, textAlign: "center" }}>
                  <h3 style={{ margin: 0 }}>{t("Course Calendar")}</h3>
                </div>
              </div>
              <br />
              <br />

              <table className="table">
                <thead>
                  <tr>
                    <th> {t("Subject")}</th>
                    <th> {t("Days")}</th>
                    <th> {t("Time")}</th>
                  </tr>
                </thead>
                <tbody>
                  {subjectsWithDetails.map(({ subject, days, time }) => (
                    <tr key={subject}>
                      <td>{subject}</td>
                      <td>{days}</td>
                      <td>{time}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {activeTab === "inbox" && renderInbox()}
          {activeTab === "bills" && renderBills()}
          {activeTab === "contact" && renderContact()}
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
