import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo1 from "../../images/logo1.png";

const CourseTeacherList = () => {
  const [coursesData, setCoursesData] = useState([]);
  const [teacher, setTeacher] = useState({ firstName: "", lastName: "" });
  const { teacherId } = useParams();
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/courses/teacher/${teacherId}`,
          {
            headers: {
              "API-Key": API_KEY, // Assuming you need an API Key for authentication
            },
          }
        );
        setCoursesData(response.data);
      } catch (error) {
        console.error("Error fetching the courses:", error);
      }
    };

    const fetchTeacherData = async () => {
      try {
        const response = await axios.get(`${API_URL}/teachers/${teacherId}`, {
          headers: {
            "API-Key": API_KEY, // Add the API Key to the request headers
          },
        });
        setTeacher(response.data);
      } catch (error) {
        console.error("Error fetching the teacher data:", error);
      }
    };

    fetchCourses();
    fetchTeacherData();
  }, [teacherId]);

  const handleAddCourse = () => {
    navigate(`/add-course-to-teacher/${teacherId}`);
  };

  const handleUpdateCourse = (id) => {
    navigate(`/update-course-teacher/${teacherId}/${id}`);
  };

  const handleDeleteCourse = async (id) => {
    try {
      await axios.delete(`${API_URL}/courses/${id}`, {
        headers: {
          "API-Key": API_KEY, // Include the API Key for secure deletion
        },
      });
      setCoursesData((prevCourses) =>
        prevCourses.filter((course) => course.id !== id)
      );
    } catch (error) {
      console.error("Error deleting the course:", error);
    }
  };

  const handleDetails = (id) => {
    navigate(`/course-details/${id}`);
  };

  const getUniqueUpcomingCourseDates = () => {
    const today = new Date();
    const uniqueCourseDates = new Set();

    coursesData.forEach((course) => {
      course.courseDays.forEach((day) => {
        let nextDate = new Date(today);
        while (nextDate.toLocaleString("en-US", { weekday: "long" }) !== day) {
          nextDate.setDate(nextDate.getDate() + 1);
        }
        if (nextDate > today) {
          uniqueCourseDates.add(nextDate.toDateString());
        }
      });
    });
    return Array.from(uniqueCourseDates).map((dateStr) => new Date(dateStr));
  };

  const uniqueUpcomingCourseDates = useMemo(getUniqueUpcomingCourseDates, [
    coursesData,
  ]);

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const today = new Date();
      if (date.toDateString() === today.toDateString()) {
        return "today-date";
      }
      if (
        uniqueUpcomingCourseDates.some(
          (courseDate) => courseDate.toDateString() === date.toDateString()
        )
      ) {
        return "course-day";
      }
    }
    return null;
  };

  const subjectsWithDetails = useMemo(() => {
    const subjectMap = {};

    coursesData.forEach((course) => {
      const { subjectName, courseDays, courseTime } = course;
      if (!subjectMap[subjectName]) {
        subjectMap[subjectName] = {
          days: new Set(),
          times: new Set(),
        };
      }
      courseDays.forEach((day) => subjectMap[subjectName].days.add(day));
      subjectMap[subjectName].times.add(courseTime);
    });

    return Object.entries(subjectMap).map(([subject, details]) => ({
      subject,
      days: Array.from(details.days).join(", "),
      time: Array.from(details.times).join(", "),
    }));
  }, [coursesData]);

  return (
    <div>
      <header className="desktop-header">
        <img src={logo1} className="menu-icon1" alt="Graduation Cap Icon" />
        <p>DZONLINESCHOOL</p>
        <div className="header-right"></div>
      </header>

      <h1>
        Course List for Teacher {teacher.firstName} {teacher.lastName}
      </h1>

      <div className="table-actions">
        <button onClick={handleAddCourse}>Add New Course</button>
      </div>
      {coursesData.length > 0 ? (
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Level</th>
              <th>Grade</th>
              <th>Subject</th>
              <th>Student Group</th>
              <th>Drive Link</th>
              <th>Live Course Link</th>

              <th>Date</th>
              <th>Time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {coursesData.map((course) => (
              <tr key={course.id}>
                <td>{course.id}</td>
                <td>{course.levelName}</td>
                <td>{course.gradeName}</td>
                <td>{course.subjectName}</td>
                <td>{course.groupStudentsNumber}</td>
                <td>
                  <a
                    href={course.driveLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {course.driveLink ? "click drive link" : "N/A"}
                  </a>
                </td>
                <td>
                  <a
                    href={course.liveCourseLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {course.liveCourseLink ? "Join Live Course" : "N/A"}
                  </a>
                </td>

                <td>{course.courseDays.join(", ")}</td>
                <td>{course.courseTime}</td>
                <td>
                  <button onClick={() => handleDetails(course.id)}>
                    Details
                  </button>
                  <button onClick={() => handleUpdateCourse(course.id)}>
                    Update
                  </button>
                  <button onClick={() => handleDeleteCourse(course.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No courses found for this teacher.</p>
      )}

      <h2>Subjects, Days, and Times</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Subject</th>
            <th>Days</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {subjectsWithDetails.map(({ subject, days, time }) => (
            <tr key={subject}>
              <td>{subject}</td>
              <td>{days}</td>
              <td>{time}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CourseTeacherList;
