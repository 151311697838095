import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/student/login.css"; // Ensure the path is correct
import AuthService from "./AuthService"; // Assuming AuthService is imported correctly

const AdminLoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // General error message
  const [emailError, setEmailError] = useState(false); // Track email error state
  const [passwordError, setPasswordError] = useState(false); // Track password error state
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailError(false); // Reset email error
    setPasswordError(false); // Reset password error
    setError(""); // Reset general error message

    try {
      // Authenticate using the AuthService (with API key in headers)
      const response = await AuthService.authenticateAdmin({ email, password });

      // If authentication is successful, store the admin data in localStorage
      AuthService.setCurrentUser(response.data);
      localStorage.setItem("adminId", response.data.id); // Store the admin ID

      // Navigate to the admin dashboard
      navigate("/admin-dashboard");
    } catch (error) {
      // Set the generic error message to "Invalid email or password"
      setError("Invalid email or password");

      // Reset specific errors
      setEmailError(false);
      setPasswordError(false);

      // Handle field-specific errors based on the server message
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message.toLowerCase();

        // Set email error if message contains 'email'
        if (errorMessage.includes("email")) {
          setEmailError(true);
        }

        // Set password error if message contains 'password'
        if (errorMessage.includes("password")) {
          setPasswordError(true);
        }
      }
    }
  };

  return (
    <div className="login">
      <div className="login-container">
        <h2 className="login-heading">Admin Login</h2>

        {/* Display the general error message */}
        {error && <p className="error">{error}</p>}

        <form onSubmit={handleSubmit} className="login-form">
          {/* Email Field */}
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email"
              style={{
                borderColor: emailError ? "red" : "#ccc",
                backgroundColor: emailError ? "#fdd" : "#fff",
              }}
            />
            {emailError && (
              <small className="error-text">Invalid email address</small>
            )}
          </div>

          {/* Password Field */}
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your password"
              style={{
                borderColor: passwordError ? "red" : "#ccc",
                backgroundColor: passwordError ? "#fdd" : "#fff",
              }}
            />
            {passwordError && (
              <small className="error-text">Invalid password</small>
            )}
          </div>

          {/* Submit Button */}
          <div className="buttonContainer">
            <button
              type="submit"
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                borderRadius: "8px",
                color: "white",
                backgroundColor: "#007bff",
                border: "none",
                cursor: "pointer",
              }}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminLoginForm;
