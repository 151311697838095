import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/quiz/video.css";

const VideoList = () => {
  const { courseId } = useParams();
  const [videos, setVideos] = useState([]);
  const navigate = useNavigate();

  // Get API URL and API Key from environment variables
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        if (courseId) {
          const response = await axios.get(
            `${API_URL}/videos/courses/${courseId}`,
            {
              headers: {
                "API-Key": API_KEY,
              },
            }
          );
          setVideos(response.data);
        }
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, [courseId, API_URL, API_KEY]);

  const handleAddVideo = () => {
    navigate(`/add-video/${courseId}`); // Redirect to add video page
  };

  const handleUpdateVideo = (videoId) => {
    navigate(`/update-video/${videoId}`); // Redirect to update video page
  };

  const handleDeleteVideo = async (videoId) => {
    try {
      await axios.delete(`${API_URL}/videos/${videoId}`, {
        headers: {
          "API-Key": API_KEY,
        },
      });
      setVideos(videos.filter((video) => video.id !== videoId)); // Update state
      alert("Video deleted successfully.");
    } catch (error) {
      console.error("Error deleting video:", error);
      alert("Failed to delete video.");
    }
  };
  return (
    <div className="video-list-container">
      <h3>Video List</h3>
      {/* Buttons Row */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        {/* Return Button */}
        <button
          onClick={() => navigate("/teacher-dashboard")}
          style={{
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            padding: "10px",
            borderRadius: "20px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaArrowLeft style={{ marginRight: "5px" }} /> Return
        </button>
        {/* Add Video Button */}
        <button
          className="add-video-button"
          onClick={handleAddVideo}
          style={{
            padding: "10px 15px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
          }}
        >
          Add Video
        </button>
      </div>
      {videos.length > 0 ? (
        <table className="video-table">
          <thead>
            <tr>
              <th>Video Name</th>
              <th>Link</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {videos.map((video) => (
              <tr key={video.id}>
                <td>{video.videoName}</td>
                <td>
                  <a
                    href={video.videoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open Video
                  </a>
                </td>
                <td>
                  <button onClick={() => handleUpdateVideo(video.id)}>
                    Update
                  </button>
                  <button onClick={() => handleDeleteVideo(video.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No videos available.</p>
      )}
    </div>
  );
};

export default VideoList;
