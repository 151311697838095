import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useNavigate, useParams } from "react-router-dom";

import logo1 from "../../images/logo1.png";

const CourseList = () => {
  const [coursesData, setCoursesData] = useState([]);
  const [student, setStudent] = useState({ firstName: "", lastName: "" });
  const [error, setError] = useState(null);
  const { studentId } = useParams();
  const navigate = useNavigate();

  // Fetching the API URL and key from .env file
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/courses/student/${studentId}`,
          {
            headers: { "API-Key": API_KEY },
          }
        );
        setCoursesData(response.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
        setError("Failed to load courses. Please try again.");
      }
    };

    const fetchStudentData = async () => {
      try {
        const response = await axios.get(`${API_URL}/students/${studentId}`, {
          headers: { "API-Key": API_KEY },
        });
        setStudent(response.data);
      } catch (error) {
        console.error("Error fetching student data:", error);
        setError("Failed to load student information. Please try again.");
      }
    };

    fetchCourses();
    fetchStudentData();
  }, [studentId]);

  const handleAddCourse = () => {
    navigate(`/add-course/${studentId}`);
  };

  const handleUpdateCourse = (courseId) => {
    navigate(`/update-course/${studentId}/${courseId}`);
  };

  const handleDeleteCourse = async (courseId) => {
    try {
      await axios.delete(`${API_URL}/courses/${courseId}`, {
        headers: { "API-Key": API_KEY },
      });
      setCoursesData(coursesData.filter((course) => course.id !== courseId));
    } catch (error) {
      console.error("Error deleting course:", error);
      setError("Failed to delete course. Please try again.");
    }
  };

  const getUniqueUpcomingCourseDates = () => {
    const today = new Date();
    const uniqueCourseDates = new Set();

    coursesData.forEach((course) => {
      course.courseDays.forEach((day) => {
        let nextDate = new Date(today);
        while (nextDate.toLocaleString("en-US", { weekday: "long" }) !== day) {
          nextDate.setDate(nextDate.getDate() + 1);
        }
        if (nextDate > today) {
          uniqueCourseDates.add(nextDate.toDateString());
        }
      });
    });
    return Array.from(uniqueCourseDates).map((dateStr) => new Date(dateStr));
  };

  const uniqueUpcomingCourseDates = useMemo(getUniqueUpcomingCourseDates, [
    coursesData,
  ]);

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const today = new Date();
      if (date.toDateString() === today.toDateString()) {
        return "today-date";
      }
      if (
        uniqueUpcomingCourseDates.some(
          (courseDate) => courseDate.toDateString() === date.toDateString()
        )
      ) {
        return "course-day";
      }
    }
    return null;
  };

  const subjectsWithDetails = useMemo(() => {
    const subjectMap = {};

    coursesData.forEach((course) => {
      const { subjectName, courseDays, courseTime } = course;
      if (!subjectMap[subjectName]) {
        subjectMap[subjectName] = {
          days: new Set(),
          times: new Set(),
        };
      }
      courseDays.forEach((day) => subjectMap[subjectName].days.add(day));
      subjectMap[subjectName].times.add(courseTime);
    });

    return Object.entries(subjectMap).map(([subject, details]) => ({
      subject,
      days: Array.from(details.days).join(", "),
      time: Array.from(details.times).join(", "),
    }));
  }, [coursesData]);

  return (
    <div>
      <div className="header">
        <img src={logo1} className="menu-icon1" alt="Graduation" />
        <p>DZONLINESCHOOL</p>
        <div className="header-right"></div>
      </div>
      <style>
        {`
          .today-date {
            background-color: #FFDD44 !important;
            color: white !important;
            border-radius: 50%;
          }
          .course-day {
            background-color: #4CAF50 !important;
            color: white !important;
            border-radius: 50%;
          }
          .action-button {
            margin-right: 5px;
          }
        `}
      </style>

      <h1 style={{ textAlign: "center" }}>
        Course List for Student {student.firstName || "Loading..."}{" "}
        {student.lastName || ""}
      </h1>
      <button onClick={handleAddCourse}>Add New Course</button>

      {error ? (
        <p>{error}</p>
      ) : (
        <>
          {coursesData.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Level</th>
                  <th>Grade</th>
                  <th>Subject</th>
                  <th>Number of Students</th>
                  <th>Live Course Link</th>
                  <th>Course Days</th>
                  <th>Time</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {coursesData.map((course) => (
                  <tr key={course.id}>
                    <td>{course.id}</td>
                    <td>{course.levelName}</td>
                    <td>{course.gradeName}</td>
                    <td>{course.subjectName}</td>
                    <td>{course.groupStudentsNumber}</td>
                    <td>
                      <a
                        href={course.liveCourseLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {course.liveCourseLink ? "Join Live Course" : "N/A"}
                      </a>
                    </td>
                    <td>{course.courseDays.join(", ")}</td>
                    <td>{course.courseTime}</td>
                    <td>
                      <button
                        className="action-button"
                        onClick={() => handleUpdateCourse(course.id)}
                      >
                        Update
                      </button>
                      <button
                        className="action-button"
                        onClick={() => handleDeleteCourse(course.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No courses found for this student.</p>
          )}
        </>
      )}

      {/* <Calendar tileClassName={tileClassName} /> */}

      {/* <h2>Upcoming Course Days</h2>
      <ul>
        {uniqueUpcomingCourseDates.map((date, index) => (
          <li key={index}>
            {date.toLocaleDateString("en-US", { weekday: "long" })}:{" "}
            {date.toLocaleDateString("en-US")}
          </li>
        ))}
      </ul> */}
      <br />
      <br />
      <br />
      <h2>Subjects, Days, and Times</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Subject</th>
            <th>Days</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {subjectsWithDetails.map(({ subject, days, time }) => (
            <tr key={subject}>
              <td>{subject}</td>
              <td>{days}</td>
              <td>{time}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CourseList;
